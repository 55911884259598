import React from "react";
import { TiSocialPinterest } from "react-icons/ti";
import {
  FaTwitter,
  FaFacebookF,
  FaLinkedinIn,
  FaRss,
  FaPhoneAlt,
} from "react-icons/fa";
import logo from "../assets/imgs/ama-sol-logo.png";

const Header = () => {
  return (
    <div>
      <header>
        <div
          className="top-bar d-flex justify-content-between align-items-center py-3 px-5"
          style={{ backgroundColor: "#0256a3" }}
        >
          <div className="text-center text-sm-center">
            <a
              href="mailto:info@amglobalnetworksolutions.com"
              className="text-decoration-none text-white"
            >
              <h5 className="m-0 p-0">
                Nationwide Call Center & Answering Service Since 2010
              </h5>
            </a>
          </div>
          <div className="link-main d-flex flex-md-row flex-sm-column justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <a
                href="https://www.facebook.com/amglobalnetworksolutioninc?mibextid=LQQJ4d"
                className="d-flex justify-content-center border rounded-circle text-white me-3 p-2"
              >
                <FaFacebookF size={18} className="link-hover" />
              </a>
              <a
                href="#"
                className="d-flex justify-content-center border rounded-circle text-white me-3 p-2"
              >
                <FaTwitter size={18} className="link-hover" />
              </a>
              <a
                href="https://www.instagram.com/amglobalnetworksolutions?igshid=OGQ5ZDc2ODk2ZA=="
                className="d-flex justify-content-center border rounded-circle text-white me-3 p-2"
              >
                <TiSocialPinterest size={20} className="link-hover" />
              </a>
              <a
                href="https://www.tiktok.com/@amglobalnetwork?_t=8iI6XD4Cavq&_r=1"
                className="d-flex justify-content-center border rounded-circle text-white me-3 p-2"
              >
                <FaLinkedinIn size={18} className="link-hover" />
              </a>
              <a
                href="#"
                className="d-flex justify-content-center border rounded-circle text-white me-5 p-2"
              >
                <FaRss size={18} className="link-hover" />
              </a>
            </div>

            <div>
              <a
                href="tel:+1 (443)-972-0500"
                className="d-flex justify-content-center align-items-center text-decoration-none text-white link-hover"
              >
                <FaPhoneAlt size={18} className="me-2" />
                <h5 className="m-0">+1 (443)-972-0500</h5>
              </a>
            </div>
          </div>
        </div>
        {/* <nav className="navbar navbar-expand-lg">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={logo} height="60" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ms-auto">
                <li className="nav-item mx-2">
                  <a className="nav-link active" aria-current="page" href="/">
                    HOME
                  </a>
                </li>
                <li className="nav-item w-100 mx-2">
                  <a className="nav-link" href="/about">
                    ABOUT US
                  </a>
                </li>
                <li className="nav-item mx-2">
                  <a className="nav-link" href="/service">
                    SERVICES
                  </a>
                </li>
                <li className="nav-item mx-2">
                  <a className="nav-link" href="/#">
                    REGIONS
                  </a>
                </li>
                <li className="nav-item mx-2">
                  <a className="nav-link" href="/#">
                    SUPPORT
                  </a>
                </li>
                <li className="nav-item w-100 mx-2">
                  <a className="nav-link" href="/#">
                    LIVE OPERATORS
                  </a>
                </li>
                <li className="nav-item mx-2">
                  <a className="nav-link" href="/#">
                    BLOGS
                  </a>
                </li>
                <button className='btn_contact'>CONTACT US</button>
              </ul>
            </div>
          </div>
        </nav> */}
        <nav class="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={logo} height="60" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item active mx-2">
                  <a class="nav-link" href="/">
                    HOME
                  </a>
                </li>
                <li class="nav-item mx-2">
                  <a class="nav-link" href="/about">
                    ABOUT US
                  </a>
                </li>
                <li class="nav-item mx-2">
                  <a class="nav-link" href="/service">
                    SERVICES
                  </a>
                </li>
                <li class="nav-item mx-2">
                  <a class="nav-link" href="#">
                    REGIONS
                  </a>
                </li>
                <li class="nav-item mx-2">
                  <a class="nav-link" href="/support">
                    SUPPORT
                  </a>
                </li>
                <li class="nav-item mx-2">
                  <a class="nav-link" href="/live-operators">
                    LIVE OPERATORS
                  </a>
                </li>
                <li class="nav-item mx-2">
                  <a class="nav-link" href="#">
                    BLOGS
                  </a>
                </li>
              </ul>
              <a href="/contact">
                <button className="btn_contact">CONTACT US</button>
              </a>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
